import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';

const IndexPage = ({ data }) => {
  const { community } = data;
  // eslint-disable-next-line no-console
  return (
    <Layout
      bgcolor={community.backgroundColour}
      wrapperClass="supporters"
      accentColor={community.accentColour}
    >
      <HelmetDatoCms seo={community.seoMetaTags} />
      <div className="container row">
        <div className="offset-sm-2 col-sm-7 col-xs-6 col">
          <h1 className="gothic--large large-title">{community.title}</h1>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: community.body,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default IndexPage;

export const query = graphql`
  query CommunityOfSupporterQuery {
    community: datoCmsCommunityOfSupporter {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      backgroundColour
      accentColour {
        hex
      }
      title
      body
    }
  }
`;
